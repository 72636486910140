import React, { useState, useEffect, useCallback, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivacyPage from './components/PrivacyPage';
import ChatWindow from './components/ChatWindow';
import newChatIcon from './assets/icons/plus-solid.svg'; 
import Sidebar from './components/Sidebar';
import FloatingTooltip from './components/FloatingTooltip';
import fullLogo from './assets/logo512.png';
import miniLogo from './assets/logosidebar.png';
import AuthPage from './components/AuthPage';
import AdminPanel from './components/AdminPanel';
import DisclaimerPopup from './components/DisclaimerPopup'; 
import Icon from './components/icon'; 
import './main.css';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [currentChatId, setCurrentChatId] = useState(null);
  const [reports, setReports] = useState([]);
  const [tooltip, setTooltip] = useState(null);
  const [generationProgress, setGenerationProgress] = useState(null);
  const [uploadedMediaPath, setUploadedMediaPath] = useState(null);
  const [uploadedMediaFile, setUploadedMediaFile] = useState(null);
  const [mediaContextCounter, setMediaContextCounter] = useState(0);
  const [isNewChat, setIsNewChat] = useState(true);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const mainContentRef = useRef(null);

  const fetchChatHistory = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/api/chat-history`, {
        credentials: 'include'
      });
      if (response.ok) {
        const data = await response.json();
        setChatHistory(data);
      } else {
        throw new Error('Failed to fetch chat history');
      }
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  }, []);

  const fetchReports = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/api/reports`, {
        credentials: 'include'
      });
      if (response.ok) {
        const data = await response.json();
        setReports(data);
      } else {
        throw new Error('Failed to fetch reports');
      }
    } catch (error) {
      console.error('Error fetching reports:', error);
    }
  }, []);
  
  useEffect(() => {
    if (isLoggedIn && !isAdmin) {
      fetchChatHistory();
      fetchReports();
    }
  }, [isLoggedIn, isAdmin, fetchChatHistory, fetchReports]);

const handleRegister = async (email, password) => {
  try {
    const response = await fetch(`${API_URL}/api/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });
    const data = await response.json();
    if (response.ok) {
      return { success: true };
    } else {
      return { error: data.message || 'Registration failed' };
    }
  } catch (error) {
    console.error('Registration error:', error);
    return { error: 'An error occurred during registration' };
  }
};

const checkAuthStatus = useCallback(async () => {
  setIsLoading(true);
  try {
    const response = await fetch(`${API_URL}/api/check-auth`, {
      credentials: 'include'
    });
    if (response.ok) {
      const data = await response.json();
      setIsLoggedIn(true);
      setIsAdmin(data.isAdmin); // Set admin status
      console.log('Auth status:', data);
      if (!data.isAdmin) {
        await fetchChatHistory();
        await fetchReports();
      }
    } else {
      setIsLoggedIn(false);
      setIsAdmin(false);
    }
  } catch (error) {
    console.error('Error checking auth status:', error);
    setIsLoggedIn(false);
    setIsAdmin(false);
  } finally {
    setIsLoading(false);
  }
}, [fetchChatHistory, fetchReports]);

useEffect(() => {
  checkAuthStatus();
}, [checkAuthStatus]);

const handleLogin = useCallback(async (email, password) => {
  try {
    const response = await fetch(`${API_URL}/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
      credentials: 'include'
    });
    
    if (response.ok) {
      const data = await response.json();
      console.log('Login successful:', data);
      
      await checkAuthStatus(); 
      setIsNewChat(true);
      setShowDisclaimer(true);
      return { success: true };
    } else {
      const errorData = await response.json();
      console.error('Login failed:', errorData);
      return { error: errorData.message || 'Login failed' };
    }
  } catch (error) {
    console.error('Login error:', error);
    return { error: 'An error occurred during login' };
  }
}, [checkAuthStatus]);

const handleLogout = useCallback(async () => {
  try {
    const response = await fetch(`${API_URL}/api/logout`, {
      method: 'POST',
      credentials: 'include'
    });
    
    if (response.ok) {
      // Clear all authentication-related state
      setIsLoggedIn(false);
      setMessages([]);
      setChatHistory([]);
      setReports([]);
      
      // Force a re-check of authentication status
      await checkAuthStatus();
    } else {
      console.error('Logout failed:', await response.text());
    }
  } catch (error) {
    console.error('Logout error:', error);
  }
}, [checkAuthStatus]);

const handleChatSelect = async (chatId) => {
  try {
    const response = await fetch(`${API_URL}/api/chat/${chatId}`, {
      credentials: 'include'
    });
    if (response.ok) {
      const data = await response.json();
      if (data.messages && Array.isArray(data.messages)) {
        const processedMessages = data.messages.map(msg => ({
          ...msg,
          mediaFile: msg.mediaUrl ? {
            type: msg.mediaType,
            url: msg.mediaUrl.startsWith('http') ? msg.mediaUrl : `${API_URL}${msg.mediaUrl}`
          } : null
        }));
        setMessages(processedMessages);
        setCurrentChatId(chatId);
        setIsNewChat(false);  // Set to false when selecting an existing chat
      } else {
        console.error('Invalid chat data received:', data);
        setMessages([]);
      }
    } else {
      throw new Error('Failed to load chat');
    }
  } catch (error) {
    console.error('Error loading chat:', error);
    setMessages([]);
  }
};

const handleNewChat = () => {
  setMessages([]);
  setCurrentChatId(null);
  setIsNewChat(true);
  setUploadedFiles([]);
  setUploadedMediaPath(null);
  setUploadedMediaFile(null);
  setMediaContextCounter(0);
};

const handleDeleteChat = async (chatId) => {
  try {
    const response = await fetch(`${API_URL}/api/chat/${chatId}`, { 
      method: 'DELETE',
      credentials: 'include'
    });
    if (response.ok) {
      setChatHistory(chatHistory.filter(chat => chat.id !== chatId));
      if (currentChatId === chatId) {
        setMessages([]);
        setCurrentChatId(null);
      }
    } else {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to delete chat');
    }
  } catch (error) {
    console.error('Error deleting chat:', error);
    // Optionally, show an error message to the user
    // setErrorMessage(error.message);
  }
};

const handleMediaUpload = useCallback(async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await fetch(`${API_URL}/api/upload-media`, {
      method: 'POST',
      body: formData,
      credentials: 'include'
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data.error) {
      throw new Error(data.error);
    }
    setUploadedMediaPath(data.filepath);
    setUploadedMediaFile(file);
  } catch (error) {
    console.error('Error uploading media:', error);
    alert(`Error uploading media: ${error.message}`);
  }
}, []);

const handleSendMessage = useCallback(async (message, mediaFile) => {
  setIsNewChat(false);
  let mediaFilepath = uploadedMediaPath;

  if (mediaFile) {
    const formData = new FormData();
    formData.append('file', mediaFile);
    try {
      const uploadResponse = await fetch(`${API_URL}/api/upload-media`, {
        method: 'POST',
        body: formData,
        credentials: 'include'
      });
      if (!uploadResponse.ok) {
        throw new Error(`HTTP error! status: ${uploadResponse.status}`);
      }
      const uploadData = await uploadResponse.json();
      mediaFilepath = uploadData.filepath;
      setUploadedMediaPath(mediaFilepath);
      setMediaContextCounter(5); // Reset counter when new media is uploaded
    } catch (error) {
      console.error('Error uploading media:', error);
      setMessages(prevMessages => [...prevMessages, { 
        text: `Error: Failed to upload media. ${error.message}`, 
        sender: 'system' 
      }]);
      return; // Exit the function if media upload fails
    }
  }

  const newUserMessage = {
    text: message,
    sender: 'user',
    mediaFile: mediaFile,
    mediaFilepath: mediaFilepath
  };

  // Immediately add the user's message and a placeholder AI message
  setMessages(prevMessages => [
    ...prevMessages, 
    newUserMessage, 
    { text: '', sender: 'ai' } // Placeholder for AI message
  ]);

  try {
    const response = await fetch(`${API_URL}/api/chat`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Accept': 'text/event-stream'
      },
      body: JSON.stringify({
        message,
        chatId: currentChatId,
        mediaFilepath: mediaFilepath,
        model: mediaFile ? 'gemini' : 'openai'
      }),
      credentials: 'include'
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const contentType = response.headers.get('Content-Type');

    if (contentType && contentType.includes('text/event-stream')) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedResponse = '';
      let chatIdFromResponse = currentChatId;
      let titleFromResponse = null;

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        
        const chunk = decoder.decode(value);
        const lines = chunk.split('\n\n');
        
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const data = JSON.parse(line.slice(6));
            if (data.content === '[DONE]') {
              setCurrentChatId(data.chat_id);
              chatIdFromResponse = data.chat_id;
              titleFromResponse = data.title;
            } else {
              accumulatedResponse += data.content;
              // Capture current values to avoid ESLint warning
              const currentAccumulatedResponse = accumulatedResponse;
              const currentDataMediaUrl = data.mediaUrl;
              const currentDataMediaType = data.mediaType;
              setMessages(prev => {
                const lastMessage = prev[prev.length - 1];
                if (lastMessage.sender === 'ai') {
                  // Update the last AI message
                  return [
                    ...prev.slice(0, -1),
                    {
                      ...lastMessage,
                      text: currentAccumulatedResponse,
                      mediaUrl: currentDataMediaUrl,
                      mediaType: currentDataMediaType
                    }
                  ];
                } else {
                  // Add AI message if not present
                  return [
                    ...prev,
                    {
                      text: currentAccumulatedResponse,
                      sender: 'ai',
                      mediaUrl: currentDataMediaUrl,
                      mediaType: currentDataMediaType
                    }
                  ];
                }
              });
            }
          }
        }
      }

      // Define aiMessage after streaming is complete
      const aiMessage = {
        text: accumulatedResponse,
        sender: 'ai',
        mediaUrl: null, // Set these if available
        mediaType: null
      };

      // Now, update chat history
      const chatIdToUse = chatIdFromResponse || currentChatId;

      if (chatIdToUse) {
        setChatHistory(prevHistory => {
          const chatExists = prevHistory.some(chat => chat.id === chatIdToUse);
          if (chatExists) {
            return prevHistory.map(chat => {
              if (chat.id === chatIdToUse) {
                return {
                  ...chat,
                  messages: Array.isArray(chat.messages)
                    ? [...chat.messages, newUserMessage, aiMessage]
                    : [newUserMessage, aiMessage]
                };
              }
              return chat;
            });
          } else {
            const newChat = {
              id: chatIdToUse,
              title: titleFromResponse,
              messages: [newUserMessage, aiMessage],
              timestamp: new Date().toISOString()
            };
            return [newChat, ...prevHistory];
          }
        });
      }

    } else {
      // Non-streaming response handling
      const data = await response.json();
      const aiMessage = { 
        text: data.response, 
        sender: 'ai',
        mediaUrl: data.mediaUrl,
        mediaType: data.mediaType
      };
      setMessages(prev => [
        ...prev,
        aiMessage
      ]);
      setCurrentChatId(data.chat_id);

      // Update chat history
      const chatIdToUse = data.chat_id || currentChatId;

      if (chatIdToUse) {
        setChatHistory(prevHistory => {
          const chatExists = prevHistory.some(chat => chat.id === chatIdToUse);
          if (chatExists) {
            return prevHistory.map(chat => {
              if (chat.id === chatIdToUse) {
                return {
                  ...chat,
                  messages: Array.isArray(chat.messages)
                    ? [...chat.messages, newUserMessage, aiMessage]
                    : [newUserMessage, aiMessage]
                };
              }
              return chat;
            });
          } else {
            const newChat = {
              id: chatIdToUse,
              title: data.title,
              messages: [newUserMessage, aiMessage],
              timestamp: new Date().toISOString()
            };
            return [newChat, ...prevHistory];
          }
        });
      }
    }

    // Media context counter logic (remains the same)
    if (mediaContextCounter > 0) {
      setMediaContextCounter(prevCounter => prevCounter - 1);
    }
    if (mediaContextCounter === 1) {
      setUploadedMediaPath(null);
    }

  } catch (error) {
    console.error('Error sending message:', error);
    setMessages(prevMessages => [
      ...prevMessages,
      { text: `Error: ${error.message}`, sender: 'system' }
    ]);
  }
}, [currentChatId, uploadedMediaPath, mediaContextCounter, setMessages, setCurrentChatId, setChatHistory]);

const handleFileUpload = useCallback(async (file) => {
  setIsNewChat(false);
  setIsProcessing(true);
  
  // Check if we've reached the limit
  if (uploadedFiles.length >= 5) {
    setMessages(prevMessages => [...prevMessages, { 
      text: 'Maximale Anzahl von 5 Dokumenten erreicht. Bitte löschen Sie zuerst ein Dokument.',
      sender: 'system' 
    }]);
    setIsProcessing(false);
    return;
  }

  const formData = new FormData();
  formData.append('file', file);
  
  try {
    const response = await fetch(`${API_URL}/api/upload`, {
      method: 'POST',
      body: formData,
      credentials: 'include'
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    setUploadedFiles(prev => [...prev, {
      filepath: data.filepath,
      filename: file.name,
      timestamp: new Date().toISOString()
    }]);
    
    setMessages(prevMessages => [
      ...prevMessages,
      { 
        id: Date.now(),
        text: `Dokument "${file.name}" erfolgreich hochgeladen. ${uploadedFiles.length + 1}/5 Dokumente vorhanden.`, 
        sender: 'system',
        temporary: true
      }
    ]);
  } catch (error) {
    console.error('Error uploading file:', error);
    setMessages(prevMessages => [...prevMessages, { 
      text: 'Fehler beim Hochladen der Datei.', 
      sender: 'system' 
    }]);
  } finally {
    setIsProcessing(false);
  }
}, [uploadedFiles.length]);

const handleRemoveFile = useCallback(async (filepath) => {
  try {
    // Optional: Add an API call to delete the file from the server
    setUploadedFiles(prev => prev.filter(file => file.filepath !== filepath));
    setMessages(prevMessages => [...prevMessages, { 
      text: 'Dokument wurde entfernt.',
      sender: 'system',
      temporary: true
    }]);
  } catch (error) {
    console.error('Error removing file:', error);
  }
}, []);

const handleDownload = (downloadLink) => {
  if (downloadLink && typeof downloadLink === 'string') {
    const fullUrl = downloadLink.startsWith('http') ? downloadLink : `${API_URL}${downloadLink}`;
    const link = document.createElement('a');
    link.href = fullUrl;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    console.error('Invalid download link:', downloadLink);
  }
};

const handleGenerateReport = async () => {
  if (!uploadedFiles.length) {
    alert('Keine Dokumentation hochgeladen');
    return;
  }

  setIsProcessing(true);
  setGenerationProgress({ 
    current: 0, 
    total: 9,  // We know it's 9 total steps (8 sections + finalization)
    sectionName: 'Initializing',
    percentage: 0  // Add explicit percentage
  });

  // Store filepaths before clearing the list
  const filesForGeneration = uploadedFiles.map(file => file.filepath);
  
  // Clear the uploaded files immediately
  setUploadedFiles([]);

  setMessages(prevMessages => prevMessages.filter(msg => !msg.text.includes('Erfolgreich hochgeladen')));

  try {
    const response = await fetch(`${API_URL}/api/generate-report`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        filepaths: filesForGeneration,
        chatId: currentChatId
      }),
      credentials: 'include'
    });
    
    const data = await response.json();
    
    switch (data.status) {
      case 'Completed':
        const newMessage = { 
          text: `Arztbrief generiert und gespeichert. Nachfolgend können Sie den Arztbrief anzeigen oder nochmals herunterladen.\n\n\n\n` +
                `Datum: ${data.generationDate}\n` +
                `Zeit: ${data.generationTime}\n\n` +
                `${data.filename} [Anzeigen](${data.view_link}) | [Herunterladen](${data.download_link})`,
          sender: 'system',
          viewLink: data.view_link,
          downloadLink: data.download_link,
          filename: data.filename,
          generationDate: data.generationDate,
          generationTime: data.generationTime
        };
        
        setMessages(prevMessages => [...prevMessages, newMessage]);
        
        if (currentChatId) {
          setChatHistory(prevHistory => 
            prevHistory.map(chat => 
              chat.id === currentChatId 
                ? { ...chat, messages: [...(chat.messages || []), newMessage] }
                : chat
            )
          );
        } else {
          const newChat = {
            id: data.chat_id,
            title: "Arztbrief-Generierung",
            messages: [newMessage],
            timestamp: new Date().toISOString()
          };
          setChatHistory(prevHistory => [newChat, ...prevHistory]);
          setCurrentChatId(data.chat_id);
        }
        
        fetchReports(); // Refresh the reports list
        break;
      
      case 'Aborted':
        setMessages(prevMessages => [...prevMessages, { 
          text: 'Arztbrief-Erstellung vom Benutzer abgebrochen.', 
          sender: 'system' 
        }]);
        break;
      
      case 'Error':
        throw new Error(data.error || 'Unknown error occurred');
      
      default:
        throw new Error('Unexpected response from server');
    }
  } catch (error) {
    console.error('Error generating report:', error);
    const errorMessage = { 
      text: `Fehler bei der Generierung des Arztbriefs: ${error.message}`, 
      sender: 'system' 
    };
    setMessages(prevMessages => [...prevMessages, errorMessage]);
    if (currentChatId) {
      await updateChatOnBackend(currentChatId, errorMessage);
    }
  } finally {
    setIsProcessing(false);
    setGenerationProgress(null);
  }
};

const handleAbortGeneration = async () => {
  try {
    const response = await fetch(`${API_URL}/api/stop-generation`, {
      method: 'POST',
      credentials: 'include'
    });
    
    if (response.ok) {
      setIsProcessing(false);
      setGenerationProgress(null);
      // We don't add a message here anymore, as handleGenerateReport will handle it
    } else {
      throw new Error('Failed to abort generation');
    }
  } catch (error) {
    console.error('Error aborting generation:', error);
    setMessages(prevMessages => [...prevMessages, { 
      text: `Fehler beim Abbrechen der Generierung: ${error.message}`, 
      sender: 'system' 
    }]);
  }
};

const updateChatOnBackend = async (chatId, newMessage) => {
  try {
    await fetch(`${API_URL}/api/chat/${chatId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message: newMessage }),
      credentials: 'include'
    });
  } catch (error) {
    console.error('Error updating chat on backend:', error);
  }
};

const openReportInNewTab = (viewLink) => {
  if (viewLink && typeof viewLink === 'string') {
    const fullUrl = viewLink.startsWith('http') ? viewLink : `${API_URL}${viewLink}`;
    window.open(fullUrl, '_blank');
  } else {
    console.error('Invalid view link:', viewLink);
  }
};  

useEffect(() => {
  let pollInterval;
  if (isProcessing) {
    console.log('Starting progress polling...'); // Debug log
    pollInterval = setInterval(async () => {
      try {
        const response = await fetch(`${API_URL}/api/generation-progress`, {
          credentials: 'include'
        });
        const data = await response.json();
        console.log('Progress data:', data); // Debug log
        
        if (data.status !== 'Completed') {
          const percentage = ((data.current_section) / 9 * 100).toFixed(2);
          setGenerationProgress({
            current: data.current_section,
            total: data.total_sections,
            sectionName: data.section_name,
            percentage: percentage
          });
        } else {
          clearInterval(pollInterval);
          setGenerationProgress(null);
          setMessages(prevMessages => [
            ...prevMessages.filter(msg => !msg.text.startsWith('Generating report:'))
          ]);
        }
      } catch (error) {
        console.error('Error polling generation progress:', error);
      }
    }, 1000);
  }
  return () => clearInterval(pollInterval);
}, [isProcessing]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleMainContentClick = (e) => {
    if (isSidebarOpen && mainContentRef.current && !e.target.closest('.sidebar')) {
      setIsSidebarOpen(false);
    }
  };

  return (
    <Router>
      <Routes>
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route 
          path="/" 
          element={
            <>
              {isLoading ? (
                <div>Loading...</div>
              ) : !isLoggedIn ? (
                <AuthPage onLogin={handleLogin} onRegister={handleRegister} />
              ) : isAdmin ? (
                <AdminPanel onLogout={handleLogout} />
              ) : (
                <div className="app">
                  <header className="app-header">
                    <div className="header-left">
                      <img
                        src={isSidebarOpen ? fullLogo : miniLogo}
                        alt="NEURIX"
                        className={`logo ${isSidebarOpen ? 'full' : 'mini'}`}
                        onClick={toggleSidebar}
                      />
                    </div>
                    <div className="header-right">
                      <div className="tooltip tooltip-bottom">
                        <button className="new-chat-btn" onClick={handleNewChat}>
                          <img src={newChatIcon} alt="New Chat" />
                        </button>
                        <span className="tooltiptext">Neuer Chat</span>
                      </div>
                      <div className="tooltip tooltip-bottom">
                        <button onClick={handleLogout} className="logout-btn" aria-label="Logout">
                          <Icon name="logout" className="logout-icon" />
                        </button>
                        <span className="tooltiptext">Abmelden</span>
                      </div>
                    </div>
                  </header>
                  <div className="app-body">
                    <Sidebar
                      chatHistory={chatHistory}
                      reports={reports}
                      onChatSelect={handleChatSelect}
                      onDeleteChat={handleDeleteChat}
                      isOpen={isSidebarOpen}
                      openReportInNewTab={openReportInNewTab}
                      onDownload={handleDownload}
                      setTooltip={setTooltip}
                      toggleSidebar={toggleSidebar}
                    />
                    <div 
                        ref={mainContentRef}
                        className={`main-content ${isSidebarOpen ? 'sidebar-open' : ''}`}
                        onClick={handleMainContentClick}
                      >
                      {showDisclaimer && (
                        <DisclaimerPopup onClose={() => setShowDisclaimer(false)} />
                      )}
                      <ChatWindow
                        messages={messages}
                        onSendMessage={handleSendMessage}
                        onFileUpload={handleFileUpload}
                        onGenerateReport={handleGenerateReport}
                        onAbortGeneration={handleAbortGeneration}
                        isOpen={isSidebarOpen}
                        isProcessing={isProcessing}
                        openReportInNewTab={openReportInNewTab}
                        onDownload={handleDownload}
                        generationProgress={generationProgress}
                        onMediaUpload={handleMediaUpload}
                        uploadedMediaFile={uploadedMediaFile}
                        setUploadedMediaFile={setUploadedMediaFile}
                        isNewChat={isNewChat}
                        uploadedFiles={uploadedFiles}    
                        onRemoveFile={handleRemoveFile} 
                      />
                      {tooltip && <FloatingTooltip {...tooltip} />}
                    </div>
                  </div>
                </div>
              )}
            </>
          } 
        />
      </Routes>
    </Router>
  );
}

export default App;